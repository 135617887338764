import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import { StripeAccountVerificationStatusEnum } from "../../../../../../common/enums/StripeAccountVerificationStatusEnum";
import { useAppSelector } from "../../../../../../common/store/redux";
import useCartRepository from "../../../../../Cart/repository/useCartRepository";
import useReviewsRepository from "../../../../../Reviews/repository/useReviewsRepository";
import StatsOverviewPanel from "../../../Components/RightSidePanel/StatsOverviewPanel/StatsOverviewPanel";
import DateAndNamePageTitleContainer from "../../DateAndNamePageTitleContainer/DateAndNamePageTitleContainer";
import StripeContainer from "../StripeContainer/StripeContainer";
import TotalOrdersBoxContainer from "../TotalOrdersBoxContainer/TotalOrdersBoxContainer";
import BalanceBoxContainer from "../BalanceBoxContainer/BalanceBoxContainer";
import EarningBoxContainer from "../EarningBoxContainer/EarningBoxContainer";
import CustomBarChartContainer from "../CustomBarChartContainer/CustomBarChartContainer";

export default function HomePageContainer() {
  const stripeAccount = useAppSelector(
    (state) => state.stripeAccount.stripeAccount
  );
  const orders = useAppSelector((state) => state.cartSlice.carts);
  const reviewsReport = useAppSelector((state) => state.reviews.reviewsReport);
  console.log("reviewsReport",reviewsReport)
  const { onFindMonthOrders } = useCartRepository();
  const { onFindAllReviews } = useReviewsRepository();

  useEffect(() => {
    !orders && onFindMonthOrders();
    !reviewsReport && onFindAllReviews();
  }, []);

  const blurClass =
    stripeAccount?.verificationStatus ===
    StripeAccountVerificationStatusEnum.VERIFIED
      ? ""
      : "blur";

  return  (
    <Grid
      container
      direction={"row"}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          flex: 1, // Allow other components to grow
          display: "flex",
          flexDirection: "column",
          gap: "25px",
        }}
      >
        <DateAndNamePageTitleContainer />
        <Grid
          container
          direction={"row"}
          sx={{ gap: "12px", marginBottom: "25px" }}
        >
          <Grid item>
            {stripeAccount?.verificationStatus ===
            StripeAccountVerificationStatusEnum.VERIFIED ? (
              <>
                <EarningBoxContainer />
              </>
            ) : (
              <>
                <StripeContainer stripeAccount={stripeAccount} />
              </>
            )}
          </Grid>
          <Grid item className={blurClass}>
            <TotalOrdersBoxContainer />
          </Grid>
          <Grid item className={blurClass}>
            <BalanceBoxContainer stripeAccount={stripeAccount} />
          </Grid>
        </Grid>
        <Grid item className={blurClass}>
          <CustomBarChartContainer />
        </Grid>
      </Box>

      {/* StatsOverviewPanel */}
      <div className={blurClass}>
      <StatsOverviewPanel />
      </div>
    </Grid>
  ) 
}
