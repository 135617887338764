import { Box, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GradientDivider from "../../../../../common/containers/GradientDivider/GradientDivider";
import CustomStripeElements from "../../../../../common/containers/Stripe/CustomStripeElements";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/store/redux";
import CartHeader from "../../../containers/Header/CartHeader";
import TotalPrice from "../../../containers/TotalPrice/TotalPrice";
import { cartActions } from "../../../slices/cartSlice";
import AddTipButton from "../AddTipsButton/AddTipButton";
import AddCustomTipModal from "../Popup/AddCustomTipModal";
import CartPaymentRecapBox from "../containers/CartRecapBox/CartPaymentRecapBox";
import useCartRepository from "../../../repository/useCartRepository";
import { menuPreviewActions } from "../../../../MenuPreview/slices/menuPreviewSlice";
import { calculateTotalCartPrice } from "../../../../../utils/cart/shared";
import { ICartEntity } from "../../../../../common/Entities/ICartEntity";
import CreateReviewBox from "../../../../Reviews/containers/CreateReviewBox/CreateReviewBox";

const CartPaymentPage = () => {
  const dispatch = useAppDispatch();
  const paymentCart = useAppSelector((state) => state.cart.paymentCart);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const userRef = urlParams.get("userRef") || "";
  const table = urlParams.get("table") || "";
  const menuId = urlParams.get("menuId") || "";
  const tip = useAppSelector((state) => state.cart.tip);
  const totalPaymentPrice = useAppSelector(
    (state) => state.cart.totalPaymentPrice
  );
  const [reviewMessage, setReviewMessage] = useState<string | null>(null);
  const [reviewRating, setReviewRating] = useState<number>(0);
  const { onFindPaymentCart } = useCartRepository();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("reviewMessage", reviewMessage);
    console.log("reviewRating", reviewRating);
  }, [reviewMessage, reviewRating]);

  const handleFindPaymentCart = async () => {
    const response = await onFindPaymentCart({
      table: table,
      userRef: userRef,
    });
    if (!response.paymentCart) {
      dispatch(menuPreviewActions.setPaymentAvailable(false));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(cartActions.clearCart());
    };
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!paymentCart) {
      handleFindPaymentCart();
    }
  }, [JSON.stringify(paymentCart)]);

  const getRemainingAmount = (cart: ICartEntity) => {
    return (
      parseFloat((calculateTotalCartPrice(cart) * 100).toFixed(2)) -
      parseFloat((totalPaymentPrice * 100).toFixed(2))
    );
  };

  return paymentCart && paymentCart?.categories.length > 0 ? (
    <>
      <AddCustomTipModal />
      <Container>
        <Grid container xs={12} mt={2}>
          <Grid item xs={12}>
            <CartHeader
              userRef={userRef}
              table={table}
              menuId={parseInt(menuId)}
              title="Régler l'addition"
              subtitle="Nous espérons que vous avez apprécié et merci pour votre commande."
            />
          </Grid>
          <Grid item xs={12}>
            <CartPaymentRecapBox cart={paymentCart} />
          </Grid>
          <Box marginTop={3} padding={2}>
            <CreateReviewBox
              reviewMessage={reviewMessage}
              setReviewMessage={setReviewMessage}
              rating={reviewRating}
              setRating={setReviewRating}
            />
          </Box>

          <Grid item container marginBottom={3} padding={2}>
            <Grid item xs={12}>
              <Typography className="listItem-heading-medium-primary">
                Ajouter un pourboire
              </Typography>
            </Grid>
            <Grid item container xs={12} mt={1}>
              <AddTipButton tips={["1.00", "2.50", "5.00"]} />
            </Grid>
            <Grid item xs={12} md={8} sm={12} lg={8} mt={4}>
              {tip > 0 && <TotalPrice variant="tip" cart={paymentCart} />}
            </Grid>
            <Grid item xs={12} md={8} sm={12} lg={8}>
              <GradientDivider />
              <TotalPrice variant="selected" cart={paymentCart} />
            </Grid>
            {totalPaymentPrice > 0 && (
              <>
                <Grid container xs={12}>
                  <CustomStripeElements
                    options={{
                      mode: "payment",
                      amount: parseFloat((totalPaymentPrice * 100).toFixed(2)),
                      currency: "eur",
                    }}
                    totalPrice={totalPaymentPrice}
                    userRef={paymentCart.userRef}
                    table={table}
                    tipAmount={tip}
                    amountRemaining={getRemainingAmount(paymentCart)}
                    reviewMessage={reviewMessage}
                    reviewRating={reviewRating}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  ) : (
    <></>
  );
};

export default CartPaymentPage;
