import { Add, MoreVertOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import useIAMRepository from "../../respository/useIAMRepository";
import { IRestaurantUserEntity } from "../../../../common/Entities/IRestaurantUserEntity";
import { IIAMTokenEntity } from "../../../../common/Entities/IIAMTokenEntity";
import useRolesRepository from "../../../Roles/repository/useRolesRepository";
import IAMTableRow from "../IAMTableRow/IAMTableRow";
import CreateIAMModal from "../Modals/CreateIAMModal";
import { iamActions } from "../../slices/iamSlices";
import DateAndNamePageTitleContainer from "../../../new/Dashboard/Containers/DateAndNamePageTitleContainer/DateAndNamePageTitleContainer";

export default function IAMMainPage() {
  const dispatch = useAppDispatch();
  const awaitingUsers = useAppSelector((state) => state.iam.awaitingUsers);
  const validUsers = useAppSelector((state) => state.iam.validUsers);
  const listOfRoles = useAppSelector((state) => state.roles.listOfRoles);
  const totalUserNbr = useAppSelector((state) => state.iam.totalUserNbr);
  const authenticatedUser = useAppSelector((state) => state.auth.user);
  const handleCreateIAM = () => {};

  const { onFindAllIAM } = useIAMRepository();
  const { onFindListOfRoles } = useRolesRepository();

  useEffect(() => {
    if (authenticatedUser) {
      if (!awaitingUsers || !validUsers) {
        onFindAllIAM({
          restaurantId: (authenticatedUser as IRestaurantUserEntity).restaurant
            .id,
          userId: authenticatedUser.userId,
        });
      }
      if (!listOfRoles) {
        onFindListOfRoles();
      }
    }
  }, [awaitingUsers, validUsers, listOfRoles, authenticatedUser]);

  const handleOpenCreateIAMModal = () => {
    dispatch(iamActions.setIsCreateIAMModalOpen(true));
  };

  return awaitingUsers && validUsers && listOfRoles ? (
    <div>
      {authenticatedUser && (
        <>
          <CreateIAMModal
            handleCreateIAM={handleCreateIAM}
            roles={listOfRoles}
            rootUserEmail={authenticatedUser.email}
          />
        </>
      )}
      <DateAndNamePageTitleContainer />
      <Grid container justifyContent={"space-between"} mb={10} pr={8} pl={8}>
        <Grid item>
          <Grid container direction={"row"} alignItems={"center"}>
            <Grid item mr={2}>
              <Typography
                sx={{ color: "#101828", fontWeight: "500", fontSize: "24px" }}
              >
                Tous les utilisateurs
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{ color: "#93312B", fontSize: "12px", fontWeight: "500" }}
              >
                {totalUserNbr}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            sx={{
              backgroundColor: "black",
              color: "white",
              textTransform: "none",
              borderRadius: "6px",
              fontSize: "14px",
              fontWeight: "300",
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.9)",
              },
            }}
            onClick={handleOpenCreateIAMModal}
          >
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                border: "2px dotted white",
                marginRight: "8px",
              }}
            >
              <Add fontSize="small" />
            </Box>{" "}
            Ajouter utilisateur
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          boxShadow: "none",
          borderRadius: 0,
          border: "none",
          "& .MuiPaper-root": {
            boxShadow: "none",
            borderRadius: 0,
            border: "none",
          },
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#273551" }}>
            <TableRow>
              <TableCell sx={{ color: "white", fontSize: "12px" }}>
                Utilisateur
              </TableCell>
              <TableCell sx={{ color: "white", fontSize: "12px" }}>
                Status
              </TableCell>
              <TableCell sx={{ color: "white", fontSize: "12px" }}>
                Role
              </TableCell>
              <TableCell sx={{ color: "white", fontSize: "12px" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {validUsers.map((user: IRestaurantUserEntity) => (
              <IAMTableRow
                firstName={user.firstName}
                lastName={user.lastName}
                previousRole={user.role}
                email={user.email}
                validatedUser={true}
                id={user.userId}
                roles={listOfRoles}
              />
            ))}
            {awaitingUsers.map((user: IRestaurantUserEntity) => (
              <IAMTableRow
                firstName={user.firstName}
                lastName={user.lastName}
                previousRole={user.role}
                email={user.email}
                validatedUser={false}
                id={user.userId}
                roles={listOfRoles}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ) : (
    <>
      <Typography>Chargement des utilisateurs...</Typography>
    </>
  );
}
