import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../common/modules/temporary/api";
import { TOnFindAllPaymentsByUserRefAndDateRequest } from "./types/Requests/TOnFindAllPaymentsByUserRefAndDateRequest";
import { TOnInitiatePaymentRequest } from "./types/Requests/TOnInitiatePaymentRequest";
import { TOnFindAllPaymentsByUserRefAndDateResponse } from "./types/Responses/TOnFindAllPaymentsByUserRefAndDateResponse";
import { TOnInitiatePaymentResponse } from "./types/Responses/TOnInitiatePaymentRespons";

export const initiatePayment = createAsyncThunk(
  "payment/initiate-payment",
  async (request: TOnInitiatePaymentRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TOnInitiatePaymentResponse>(
      dispatch,
      "/api/payment/initiate-payment",
      MethodEnum.POST,
      request,
      false
    );

    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const onFindAllPaymentsByUserRefAndDate = createAsyncThunk(
  "payment/find-allByUserRefAndDate",
  async (
    request: TOnFindAllPaymentsByUserRefAndDateRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response =
      await apiRequest<TOnFindAllPaymentsByUserRefAndDateResponse>(
        dispatch,
        `api/payment/find-allByUserRefAndDate/${request.startDate}/${request.endDate}`,
        MethodEnum.GET,
        request,
        false
      );
    if (response.success) {
      return response.data;
    } else {
      return rejectWithValue(response);
    }
  }
);
