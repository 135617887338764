import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import MenusNavigationBar from "../MenusNavigationBar/MenusNavigationBar";
import { TEditableNavigationBarProps } from "./types/TEditableNavigationBarProps";

export default function EditableNavigationBar(
  props: TEditableNavigationBarProps
) {
  const navigationBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const modalElement = document.querySelector(".MuiDialog-root"); // Sélectionne la modal

      if (
        navigationBarRef.current &&
        !navigationBarRef.current.contains(event.target as Node) &&
        !modalElement?.contains(event.target as Node) // Ignore les clics dans la modal
      ) {
        props.setIsEditing(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.15)",
          position: "absolute",
          zIndex: 10,
          left: 0,
          bottom: 0,
        }}
      ></Box>
      <Box style={{ zIndex: 15 }} ref={navigationBarRef} width={"100%"}>
        <MenusNavigationBar
          nameList={props.editableTitleList}
          selectedIndex={props.selectedIndex}
          setSelectedIndex={props.setSelectedIndex}
        />
      </Box>
    </>
  );
}
