import { Avatar, Grid, MenuItem, Typography } from "@mui/material";
import React from "react";
import { TSettingsButtonProps } from "./types/TSettingsButtonProps";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function SettingsButton(props: TSettingsButtonProps) {
  const navigate = useNavigate();
  return (
    <>
      <MenuItem
        sx={{
          width: "100%",
          mt: 1,
          transition: "background-color 0.3s",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Slightly transparent on hover
          },
        }}
        onClick={
          props.redirectTo
            ? () => {
                navigate(`/dashboard/${props.redirectTo}`);
                props.closeMenu && props.closeMenu();
              }
            : props.onClick
        }
      >
        <Grid container alignItems="center">
          <Grid item>
            {props.icon ? (
              <Grid container alignItems="center">
                {props.icon}
              </Grid>
            ) : (
              <Avatar />
            )}
          </Grid>
          <Grid item xs ml={1}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                color: "#757575",
              }}
            >
              {props.title}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <KeyboardArrowRight sx={{ color: "#7F7F7F" }} />
            </Grid>
          </Grid>
        </Grid>
      </MenuItem>
    </>
  );
}
