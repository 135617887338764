import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import { TCustomReviewTextFieldProps } from "./types/TCustomReviewTextFieldProps";

export default function CreateReviewTextField(
  props: TCustomReviewTextFieldProps
) {
  const { control,watch } = useFormContext();
  const fieldValue = watch(props.name);

  useEffect(()=>{
    if(fieldValue === "" ||!fieldValue){
      props.setReviewMessage(null);
    }
    else{
      props.setReviewMessage(fieldValue);
    }
  },[fieldValue])

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={props.label}
          value={field.value || ""}
          variant="outlined"
          type="email"
          error={!!error}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          sx={{
            backgroundColor: "rgba(117, 117, 117, 0.35)",
            color: "black",
            "& .MuiInputBase-input": {
              fontSize: "16px", // Font size for input text
              fontWeight: "500", // Font weight for input text
              color: "black", // Input text color
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none", // Removes the border
                borderRadius: "15px",
              },
            },
          }}
        />
      )}
    />
  );
}
