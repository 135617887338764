import { Box, Grid } from "@mui/material";
import DailyOrderCardContainer from "../../../Containers/HomePage/DailyOrderCardContainer/DailyOrderCardContainer";
import ReviewsOverviewContainer from "../../../Containers/HomePage/ReviewsOverviewContainer/ReviewsOverviewContainer";
import NotificationBell from "../../../../../../common/components/NotificationBell/NotificationBell";
import UserProfileCardContainer from "../../../../../../common/containers/UserProfileCardContainer/UserProfileCardContainer";

export default function StatsOverviewPanel() {
  return (
    <Box
      sx={{
        position: "fixed", // Stick to viewport
        top: 0,
        right: 0,
        backgroundColor: "white",
        height: "650px", // Default height
        width: "370px", // Default width
        zIndex: 9999, // Ensure it's above other items
        borderLeft: "2px solid",
        borderBottom: "2px solid",
        borderColor: "#E4E4E4",
        borderBottomLeftRadius: "5px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Optional shadow
        transition: "width 0.3s ease, height 0.3s ease", // Smooth resizing
        "@media (max-width: 1330px)": {
          width: "300px", // Reduce width for medium screens
          height: "650px", // Reduce height for medium screens
        },
        "@media (max-width: 768px)": {
          width: "200px", // Reduce width further for small screens
          height: "650px", // Reduce height further for small screens
        },
      }}
    >
      <Box sx={{ padding: "15px" }}>
        <Grid
          container
          direction={"row"}
          sx={{ width: "100%", justifyContent: "space-evenly" }}
        >
          <Grid item>
            <NotificationBell />
          </Grid>
          <Grid item>
            <UserProfileCardContainer />
          </Grid>
        </Grid>
        <DailyOrderCardContainer />
        <ReviewsOverviewContainer />
      </Box>
    </Box>
  );
}
