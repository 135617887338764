import { useState } from "react";
import { AddCircleRounded } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { useAppDispatch } from "../../../../common/store/redux";
import { menuPreviewActions } from "../../slices/menuPreviewSlice";
import { TItemPreviewModalProps } from "./types/TItemPreviewModalProps";
import ItemCustomizationDetails from "./ItemCustomizationDetails";

export default function ItemPreviewModal(props: TItemPreviewModalProps) {
  const dispatch = useAppDispatch();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleOpenItemModal = () => {
    dispatch(menuPreviewActions.setOpenItemModal(true));
    dispatch(menuPreviewActions.setSelectedItem(props.item));
    dispatch(menuPreviewActions.setSelectedCategory(props.category));
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
    setImageError(false);
  };

  const handleImageError = () => {
    setImageLoaded(false);
    setImageError(true);
  };

  console.log(
    "propssssssssssssssssssssssssssssssssssssssssssss",
    props.item.menuItemAllergen
  );
  return (
    <div onClick={handleOpenItemModal}>
      <Grid container xs={12} mb={2} mt={2}>
        <Grid container xs={12} padding={"16px"}>
          <Grid container alignItems={"center"} xs={12} display={"flex"}>
            <Typography
              sx={{ fontSize: "27px", fontWeight: "bold", color: "black" }}
            >
              {props.item.name}
            </Typography>
            {props.modalItem &&
              props.item.menuItemAllergen.map((menuItemAllergen) => {
                return (
                  <Box
                    sx={{
                      backgroundColor: "rgba(129, 129, 129, 0.11)",
                      borderRadius: "24px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      pl: 2,
                      pr: 2,
                      height: "16px",
                      mt: "5px",
                      ml: "10px",
                      boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.3)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#E09C4A",
                        fontWeight: "bold",
                      }}
                    >
                      {menuItemAllergen.menuAllergen.name}
                    </Typography>
                  </Box>
                );
              })}
          </Grid>
          <Grid item xs={12} mt={1}>
            <Typography
              sx={{ fontSize: "15px", color: "#818181", fontWeight: "300" }}
              className={"font-inter"}
            >
              {props.item.description}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Typography
              sx={{ fontSize: "15px", color: "#818181", fontWeight: "300" }}
              className={"font-inter"}
            >
              €{props.item.price}
            </Typography>
          </Grid>
        </Grid>
        {props.item.menuItemCustomizations.length > 0 && (
          <ItemCustomizationDetails
            menuItemCustomizations={props.item.menuItemCustomizations}
          />
        )}

        {!props.modalItem && (
          <Grid xs={4} className="grid-center" padding={"16px"}>
            <Grid margin={1} className="grid-center" marginLeft={2}>
              {!props.item.menuItemImage.image.url || imageError ? (
                <AddCircleRounded
                  style={{ color: "#062e4b" }}
                  onClick={() => {}}
                />
              ) : (
                <img
                  src={`${props.item.menuItemImage.image.url}`}
                  width={"100%"}
                  height={"100%"}
                  onLoad={handleImageLoad}
                  onError={handleImageError}
                  style={{ display: imageLoaded ? "block" : "none" }}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
