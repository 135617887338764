import { Box, Typography } from "@mui/material";
import React from "react";
import { TCustomButtonProps } from "./types/TCustomButtonProps";

export default function CustomButton(props: TCustomButtonProps) {
  return (
    <Box
      sx={{
        backgroundColor: props.color ? props.color : "#FBBC04",
        borderRadius: "8px",
        cursor: "pointer",
      }}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        props.onClick();
        console.log("cici");
      }}
    >
      {React.isValidElement(props.content) ? (
        props.content
      ) : (
        <Typography
          className="font-barlow"
          sx={{
            fontSize: "14px",
            color: "white",
            marginTop: "14px",
            marginBottom: "14px",
            marginLeft: "25px",
            marginRight: "25px",
            textAlign: "center",
          }}
        >
          {props.content}
        </Typography>
      )}
    </Box>
  );
}
