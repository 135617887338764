import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReviewsEntity } from "../../Entities/IReviewsEntity";
import { TReviewSliceState } from "./types/TReviewSliceState";

const initialState: TReviewSliceState = {
  reviews: null,
};

const rSlice = createSlice({
  name: "rSlice",
  initialState,
  reducers: {
    setReviews: (state, action: PayloadAction<IReviewsEntity[]>) => {
      //state.reviews = action.payload;
    },
  },
});

export const rSliceReducer = rSlice.reducer;
export const rSliceActions = rSlice.actions;
