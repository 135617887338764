import { Checkbox, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TPickSupplementProps } from "./types/TPickSupplementProps";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import { menuPreviewActions } from "../../slices/menuPreviewSlice";
import { AddCircleRounded, RemoveCircleRounded } from "@mui/icons-material";

export default function PickSupplement(props: TPickSupplementProps) {
  const itemToAdd = useAppSelector((state) => state.menuPreview.itemToAdd);
  const calculateSelectedQuantity = () => {
    const foundSupplement = itemToAdd?.cartSupplements?.find(
      (supplement) => supplement.supplement.id === props.supplement.id
    );

    return foundSupplement?.quantity || 0;
  };
  const [selectedQuantity, setSelectedQuantity] = useState<number>(
    calculateSelectedQuantity()
  );
  // Check if max quantity has been reached for the customization
  const checkIfDisabled = () => {
    const found = itemToAdd?.cartSupplements.find(
      (s) => s.supplement.id === props.supplement.id
    );
    if (found) {
      console.log("found", found);
      if (props.supplement.maxQuantity >= found.quantity) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
    setIsDisabled(false);
  };
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  // Update selected quantity and disabled status whenever itemToAdd changes
  useEffect(() => {
    setSelectedQuantity(calculateSelectedQuantity());
    checkIfDisabled();
  }, [JSON.stringify(itemToAdd)]);

  const dispatch = useAppDispatch();
  const handleAddSupplement = () => {
    dispatch(menuPreviewActions.addSupplement(props.supplement));
  };
  const handleRemoveSupplement = () => {
    dispatch(menuPreviewActions.removeSupplement(props.supplement));
  };
  return (
    <Grid item width={"100%"} xs={12} md={12}>
      <Grid item paddingLeft="16px" paddingRight="16px">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Grid item>
            <Grid container direction={"column"}>
              <Grid item>
                <Typography
                  sx={{ color: "black", fontWeight: "400", fontSize: "18px" }}
                >
                  {props.supplement.name}
                </Typography>
              </Grid>
              <Grid item>
                {selectedQuantity > 0
                  ? `+${props.supplement.price * selectedQuantity}€`
                  : `${props.supplement.price}€`}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {selectedQuantity === 0 ? (
              <AddCircleRounded
                sx={{ color: isDisabled ? "#D9D9D9" : "#273551" }}
                onClick={handleAddSupplement}
              />
            ) : (
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <RemoveCircleRounded
                    sx={{ color: "#818181" }}
                    onClick={handleRemoveSupplement}
                  />
                </Grid>
                <Grid item>{selectedQuantity}</Grid>
                <Grid item>
                  <AddCircleRounded
                    sx={{ color: isDisabled ? "#D9D9D9" : "#273551" }}
                    onClick={handleAddSupplement}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
