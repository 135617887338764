import { LoadingStatesEnum } from "../../../../../../common/enums/LoadingStatesEnum";
import { useAppSelector } from "../../../../../../common/store/redux";
import ReviewsOverviewCard from "../../../Components/RightSidePanel/Review/ReviewsOverviewCard/ReviewsOverviewCard";

export default function ReviewsOverviewContainer() {
   const reviewsReport = useAppSelector((state) => state.reviews.reviewsReport);
   
  return (
    <ReviewsOverviewCard
      reviews={reviewsReport?.reviewsList ?? []}
      loadingState={LoadingStatesEnum.SUCCESS}
    />
  );
}
