import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { TMenusNavigationBarProps } from "./types/TMenusNavigationBarProps";

export default function MenusNavigationBar(props: TMenusNavigationBarProps) {
  const handleClick = (index: number) => {
    props.setSelectedIndex(index);
    props.handleClick && props.handleClick(index)
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F1F1F1",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        padding: "8px",
        borderRadius: "42px",
        maxWidth: "100%", // Adjust to the max width you want
      }}
    >
      <Grid
        direction={"row"}
        sx={{
          scrollSnapType: "x mandatory", // Enable snap scrolling
          overflowX: "auto", // Enable horizontal scroll
          whiteSpace: "nowrap", // Prevent wrapping of items to the next line
          "&::-webkit-scrollbar": {
            display: "none", // Chrome, Safari, Edge
          },
          scrollbarWidth: "none", // Firefox
          display: "flex",
        }}
      >
        {props.nameList.map((name, index) => {
          return (
            <div onClick={() => handleClick(index)} key={index}>
              {React.isValidElement(name) ? (
                name
              ) : (
                <Box
                   // Add key for better performance and avoid warnings
                  sx={{
                    backgroundColor:
                      props.selectedIndex === index ? "#FBBC04" : "",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    borderRadius: "17px",
                    cursor: "pointer",
                    display: "inline-block", // Ensure items stay in a horizontal line
                    marginRight: "8px", // Optional spacing between items
                    scrollSnapAlign: "start", // Ensure each item snaps into view
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: props.selectedIndex === index ? "16px" : "14px",
                      color:
                        props.selectedIndex === index ? "white" : "#383838",
                      fontWeight: "500",
                      "&::first-letter": {
                        textTransform: "uppercase",
                      },
                    }}
                  >
                    {name}
                  </Typography>
                </Box>
              )}
            </div>
          );
        })}
      </Grid>
    </Box>
  );
}
