import React from "react";
import { useAppSelector } from "../../store/redux";
import { Box, Grid, Typography } from "@mui/material";
import UserNameAndRoleCard from "../../components/UserNameAndRoleCard/UserNameAndRoleCard";
import SettingsMenu from "../../../modules/Settings/containers/SettingsMenu/SettingsMenu";
import { getUserFriendlyRoleName } from "../../../utils/roles/shared";
import UserProfileSettingsIcon from "../../components/UserProfileSettingsIcon/UserProfileSettingsIcon";

export default function UserProfileCardContainer() {
  const authenticatedUser = useAppSelector((state) => state.auth.user);

  return authenticatedUser ? (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Grid item>
        <UserNameAndRoleCard
          firstName={authenticatedUser.firstName}
          lastName={authenticatedUser.lastName}
          roleName={getUserFriendlyRoleName(authenticatedUser.role.name)}
        />
      </Grid>
      <Grid item>
        <SettingsMenu icon={<UserProfileSettingsIcon />} />
      </Grid>
    </Box>
  ) : (
    <></>
  );
}
