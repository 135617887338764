import { Typography } from "@mui/material";
import React from "react";
import { CustomForm } from "../../../../common/containers/Form/CustomForm";
import CreateReviewTextField from "./CreateReviewTextField";
import { TCreateReviewBoxProps } from "./types/TCreateReviewBoxProps";
import { StarRating } from "./StarRating";

export default function CreateReviewBox(props: TCreateReviewBoxProps) {
  return (
    <>
      <Typography
        sx={{ fontWeight: "bold", fontSize: "18px", fontColor: "black" }}
      >
        Allez vous laisser un commentaire ?
        <span style={{ color: "#818181", fontSize: "11px", fontWeight: "400" }}>
          Optionnel
        </span>
      </Typography>
      <CustomForm
        useCustomButton={true}
        useDefaultErrorDisplay={false}
        onSuccess={() => {}}
        onSubmit={() => {}}
        name={"create-review"}
      >
        <CreateReviewTextField name={"reviewMessage"} label={""} setReviewMessage={props.setReviewMessage} />
      </CustomForm>
      <StarRating rating={props.rating} setRating={props.setRating} />
    </>
  );
}
