import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import PagesNavigation from "../../../../common/containers/PagesNavigation/PagesNavigation";
import { IReviewsEntity } from "../../../../common/Entities/IReviewsEntity";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import useReviewsRepository from "../../repository/useReviewsRepository";
import AppreciationStatBox from "../AppreciationStatBox/AppreciationStatBox";
import ReviewMessageBox from "../ReviewMessageBox/ReviewMessageBox";
import TotalReview from "../TotalReviews/TotalReview";
import DateAndNamePageTitleContainer from "../../../new/Dashboard/Containers/DateAndNamePageTitleContainer/DateAndNamePageTitleContainer";

type ReviewType = "MAUVAIS" | "BON" | "NORMAL" | "EXCELLENT";

interface IReviewWithType extends IReviewsEntity {
  type: ReviewType;
}

export default function MainReviewsPage() {
  const { onFindAllReviews } = useReviewsRepository();
  const reviewsReport = useAppSelector((state) => state.reviews.reviewsReport);
  const dispatch = useAppDispatch();

  useEffect(() => {
    //Get all reviews
    onFindAllReviews();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 4;

  const getPagedReviews = () => {
    const allReviews: Record<ReviewType, IReviewsEntity[]> = {
      MAUVAIS: [],
      NORMAL: [],
      BON: [],
      EXCELLENT: [],
    };

    reviewsReport?.reviewsIndicatorList.forEach((indicator) => {
      indicator.reviewsList.forEach((review) => {
        allReviews[indicator.type as ReviewType].push(review);
      });
    });

    const paginatedReviews: Record<ReviewType, IReviewsEntity[][]> = {
      MAUVAIS: [],
      NORMAL: [],
      BON: [],
      EXCELLENT: [],
    };

    for (const type of Object.keys(allReviews) as ReviewType[]) {
      for (let i = 0; i < allReviews[type].length; i += reviewsPerPage) {
        paginatedReviews[type].push(
          allReviews[type].slice(i, i + reviewsPerPage)
        );
      }
    }

    return paginatedReviews;
  };

  const paginatedReviews = getPagedReviews();
  const maxPages = Math.max(
    paginatedReviews.MAUVAIS.length,
    paginatedReviews.NORMAL.length,
    paginatedReviews.BON.length,
    paginatedReviews.EXCELLENT.length
  );

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const isSmScreen = useMediaQuery(theme.breakpoints.only("sm"));
  const isMdScreen = useMediaQuery(theme.breakpoints.only("md"));

  const getColumnWidth = () => {
    if (isXsScreen) return "100%";
    if (isSmScreen) return "calc(50% - 20px)";
    if (isMdScreen) return "calc(33.33% - 18px)";
    return "calc(25% - 12px)";
  };

  const renderReviewGroup = (type: ReviewType, reviews: IReviewsEntity[]) => (
    <Grid item sx={{ width: getColumnWidth() }} key={type}>
      <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
        {type}
      </Typography>
      <Grid
        item
        border={1}
        sx={{ borderColor: getTypeColor(type), border: "2px solid" }}
        mb={3}
      ></Grid>
      {reviews.map((review) => (
        <ReviewMessageBox key={review.id} review={review} />
      ))}
    </Grid>
  );

  const getTypeColor = (type: ReviewType) => {
    switch (type) {
      case "MAUVAIS":
        return "#F03738";
      case "NORMAL":
        return "#FBBC04";
      case "BON":
        return "#05FF78";
      case "EXCELLENT":
        return "#00AC4F";
      default:
        return "#000";
    }
  };

  return reviewsReport ? (
    <Grid container sx={{ width: "100%" }} direction={"column"}>
      <DateAndNamePageTitleContainer />
      <Grid container>
        <TotalReview reviewsReport={reviewsReport} />

        <AppreciationStatBox
          reviewsIndicators={reviewsReport?.reviewsIndicatorList || []}
        />
      </Grid>
      <Grid item>
        <Grid container gap={2} direction={"row"}>
          {reviewsReport && (
            <>
              {(["MAUVAIS", "NORMAL", "BON", "EXCELLENT"] as ReviewType[]).map(
                (type) =>
                  renderReviewGroup(
                    type,
                    paginatedReviews[type][currentPage - 1] || []
                  )
              )}
            </>
          )}
        </Grid>
      </Grid>

      <PagesNavigation
        currentPage={currentPage}
        setPage={setCurrentPage}
        pagesNumber={maxPages}
      />
    </Grid>
  ) : (
    <></>
  );
}
