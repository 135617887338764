import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { initiatePayment } from "../../../modules/Payment/services/paymentServices";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { CustomForm } from "../Form/CustomForm";
import { TStripeCheckoutFormProps } from "./types/TStripeCheckoutFormProps";
import { ICartItemEntity } from "../../Entities/ICartItemEntity";
import { ICartEntity } from "../../Entities/ICartEntity";
import { Box } from "@mui/material";
import usePaymentRepository from "../../../modules/Payment/repository/usePaymentRepository";
import useReviewsRepository from "../../../modules/Reviews/repository/useReviewsRepository";

export default function StripeCheckoutForm(props: TStripeCheckoutFormProps) {
  const dispatch = useAppDispatch();
  const finalCart = useAppSelector((state) => state.cart.finalCart);
  const stripe = useStripe();
  const elements = useElements();
  const { onInitiatePayment } = usePaymentRepository();
  const { onCreateReview } = useReviewsRepository();

  const getCartItemsFromFinalCart = (
    finalCart: ICartEntity
  ): ICartItemEntity[] => {
    return finalCart.categories.flatMap((category) => {
      return category.items.map((item) => item);
    });
  };
  const handleOnSubmit = async () => {
    if (stripe && elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        console.log("error stripe", submitError);
        return;
      }
      if (props.reviewRating > 0 || props.reviewMessage) {
        await onCreateReview({
          review: {
            rating: props.reviewRating,
            message: props.reviewMessage ?? "",
            tableNumber: parseInt(props.table),
          },
          userRef: props.userRef,
        });
      }
      const response = await onInitiatePayment({
        price: props.totalPrice,
        cartItems: getCartItemsFromFinalCart(finalCart),
        userRef: props.userRef,
        tipAmount: props.tipAmount,
        table: props.table,
        amountRemaining: props.amountRemaining,
      });
      if (response) {
        handleOnSuccess(response.client_secret ?? "");
      } else {
        console.log("erreur lors du paiement");
      }
    }
  };
  const handleOnSuccess = async (clientSecret: string) => {
    // Confirm the PaymentIntent using the details collected by the Payment Element
    if (stripe && elements) {
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: "https://example.com/order/123/complete",
        },
      });

      if (error) {
        console.log("error stripe", error);
      }
    }
  };
  return (
    <CustomForm
      defaultButtonText="Payer"
      onSubmit={handleOnSubmit}
      onSuccess={() => {}}
      submitDisabled={!stripe}
      name="stripe-checkout-form"
      useDefaultErrorDisplay={false}
    >
      <Box mb={4} p={2}>
        <PaymentElement />
      </Box>
    </CustomForm>
  );
}
