import { IReviewsReportsEntity } from "../../../common/Entities/IReviewsReportEntity";
import { useAppDispatch } from "../../../common/store/redux";
import { createReview, findAllReviews } from "../services/reviewsService";
import { TCreateReviewRequest } from "../services/types/Requests/TCreateReviewRequest";
import { TCreateReviewResponse } from "../services/types/Responses/TCreateReviewResponse";
import { reviewsActions } from "../slices/reviewsSlices";

export default function useReviewsRepository() {
  const dispatch = useAppDispatch();

  async function onCreateReview(
    request: TCreateReviewRequest
  ): Promise<TCreateReviewResponse> {
    const resultAction = await dispatch(createReview(request));

    if (createReview.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    return resultAction.payload;
  }

  async function onFindAllReviews(): Promise<IReviewsReportsEntity> {
    const resultAction = await dispatch(findAllReviews());

    if (findAllReviews.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const foundReport = resultAction.payload.report;
    let averageReviewScore = 0;
    if (foundReport.reviewsList.length > 0) {
      averageReviewScore = foundReport.reviewsList.reduce(
        (actual, previous) => actual + previous.rating,
        0
      );
      averageReviewScore = Number(
        (averageReviewScore / foundReport.reviewsList.length).toPrecision(1)
      );
    }
    console.log("foundReport", foundReport);
    dispatch(reviewsActions.setReviewsReport(foundReport));
    dispatch(reviewsActions.setAverageReviewScore(averageReviewScore));
    //dispatch(rSliceActions.setReviews(foundReport.reviewsList));
    return foundReport;
  }
  return {
    onFindAllReviews,
    onCreateReview,
  };
}
