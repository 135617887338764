import React from "react";
import { ReactComponent as BellIcon } from "../../images/header_bell_icon.svg";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NotificationBell() {
  const navigate = useNavigate();
  return (
    <IconButton
      sx={{ mr: 1 }}
      onClick={() => {
        navigate("/dashboard/notifications");
      }}
    >
      <BellIcon />
    </IconButton>
  );
}
