import {
  GroupOutlined,
  LogoutOutlined,
  QrCodeOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../common/store/redux";
import useAuthRepository from "../../../Auth/repository/useAuthRepository";
import useCartRepository from "../../../Cart/repository/useCartRepository";
import SettingsBox from "../SettingsBox/SettingsBox";
import SettingsButton from "../SettingsButton/SettingsButton";
import { getUserFriendlyRoleName } from "../../../../utils/roles/shared";
import { TSettingsMenuProps } from "./types/TSettingsMenuProps";

export default function SettingsMenu(props: TSettingsMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { onSignOut } = useAuthRepository();
  const { onFindMonthOrders } = useCartRepository();
  const monthOrders = useAppSelector((state) => state.cart.monthOrders);
  const [isChef, setIsChef] = useState<boolean>(false);
  const [isWaiter, setIsWaiter] = useState<boolean>(false);
  const authenticatedUser = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    if (authenticatedUser) {
      if (
        authenticatedUser.role.name !== "CHEF" &&
        authenticatedUser.role.name !== "WAITER"
      ) {
        !monthOrders && onFindMonthOrders();
      }
      setIsChef(true);
      if (authenticatedUser.role.name === "CHEF") {
        setIsChef(true);
      } else {
        setIsChef(false);
      }
      if (authenticatedUser.role.name === "WAITER") {
        setIsWaiter(true);
      } else {
        setIsWaiter(false);
      }
    }
  }, [authenticatedUser, monthOrders]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    await onSignOut();
  };

  const connectedUser = useAppSelector((state) => state.auth.user);

  useEffect(() => {}, []);

  return connectedUser ? (
    <>
      <div onClick={handleClick}>{props.icon}</div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ zIndex: 99999 }}
      >
        <Box sx={{ p: 2, width: 300 }}>
          <Grid container direction="column">
            <Grid item m={2}>
              <Grid item>
                <Typography
                  sx={{ fontSize: "16px", color: "black" }}
                  fontWeight="bold"
                >
                  {connectedUser?.userRef}
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", color: "#757575", fontWeight: "500" }}
                >
                  {connectedUser?.role?.name
                    ? getUserFriendlyRoleName(connectedUser?.role?.name)
                    : ""}
                </Typography>
              </Grid>
              {!isChef && (
                <Grid item mt={1}>
                  <SettingsBox />
                </Grid>
              )}
            </Grid>
            <Grid item mt={1}>
              <Divider />
            </Grid>
            {!isChef && (
              <>
                <Grid item mt={1}>
                  <SettingsButton
                    title="Paramètres du compte"
                    onClick={() => {}}
                    icon={<SettingsOutlined sx={{ color: "black" }} />}
                  />
                  {!isWaiter && (
                    <SettingsButton
                      title="Gérer les utilisateurs"
                      redirectTo="iam"
                      onClick={() => {}}
                      icon={<GroupOutlined sx={{ color: "black" }} />}
                    />
                  )}

                  <SettingsButton
                    title="Accéder au menu"
                    onClick={() => {}}
                    icon={<QrCodeOutlined sx={{ color: "black" }} />}
                    redirectTo="redirect-to-menupreview"
                    closeMenu={handleClose}
                  />
                </Grid>
                <Grid item mt={1}>
                  <Divider />
                </Grid>
              </>
            )}
            <Grid item mt={1}>
              <SettingsButton
                title="Déconnexion"
                onClick={handleSignOut}
                icon={<LogoutOutlined sx={{ color: "black" }} />}
              />
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </>
  ) : (
    <></>
  );
}
